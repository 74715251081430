/* General Styling */
#technology {
    background-color: #fdf6e3; /* Light cream background */
    padding: 4rem 2rem;
  }
  
  .tech-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
  }
  
  /* Header Styling */
  .tech-header h1 {
    color: #718d4b; /* Olive green color */
    font-size: 2.5rem;
    margin-bottom: 2rem;
    text-align: center;
  }
  
  /* Content Section */
  .tech-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
  }
  
  /* Text Section */
  .tech-text {
    flex: 1;
    min-width: 300px;
  }
  
  .tech-text p {
    color: #6d6d6d; /* Gray text */
    line-height: 1.8;
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
  }
  
  .tech-link {
    display: inline-block;
    margin-top: 1rem;
    color: #ff8c00; /* Orange link */
    font-weight: bold;
    text-decoration: none;
  }
  
  .tech-link:hover {
    text-decoration: underline;
  }
  
  /* Images Section */
  .tech-images {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
  
  .circle-image {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
    border: 4px solid #718d4b; /* Olive green border */
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .circle-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    .tech-content {
      flex-direction: column;
      text-align: center;
    }
  
    .tech-text {
      text-align: center;
    }
  
    .circle-image {
      width: 150px;
      height: 150px;
    }
  }
  
  