#header {
  position: relative;
  background: linear-gradient(
      rgba(0, 0, 0, 0.7), /* Better opacity for readability */
      rgba(0, 0, 0, 0.7)
    ),
    url('../../assets/home-page-background.jpg') no-repeat center center/cover;
  height: 100vh;
  display: flex;
  flex-direction: column; /* Align logo and text vertically */
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--color-white);
  padding: 20px; /* Added padding for breathing room */
  box-sizing: border-box;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 900px; /* Prevent the content from stretching too much */
  width: 100%;
}

.header-logo {
  max-width: 800px; /* Restrict logo size for better proportionality */
  width: 100%;
  height: auto;
  margin-bottom: 20px; /* Add spacing below the logo */
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.5)); /* Add a subtle shadow for emphasis */
}

.header h1 {
  font-size: 2.5rem; /* Adjust for a balanced look */
  line-height: 1.2;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 1rem;
}

.header h1 span {
  color: var(--accent-color); /* Keep accent color for branding */
}

.header p {
  margin: 1rem 0;
  color: #ddd;
  font-size: 1.2rem;
  line-height: 1.6;
}

.header .cta {
  margin-top: 1.5rem;
  padding: 0.8rem 2rem;
  background-color: var(--accent-color);
  color: #fff;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  text-decoration: none;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.header .cta:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 768px) {
  #header {
    height: auto;
    padding: 40px 20px;
  }

  .header-logo {
    max-width: 200px; /* Adjust logo size for smaller screens */
  }

  .header h1 {
    font-size: 2rem;
  }

  .header p {
    font-size: 1rem;
  }

  .header .cta {
    padding: 0.7rem 1.5rem;
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 480px) {
  .header h1 {
    font-size: 1.8rem;
  }

  .header p {
    font-size: 0.9rem;
  }

  .header .cta {
    padding: 0.6rem 1.2rem;
    font-size: 0.8rem;
  }
}
