@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
:root {
  --font-family: "Poppins", sans-serif;
  --dark-blue: #4CAF50;
  --light-blue: #4CAF50;

  --color-white: #fff;
  --dark-text:#4CAF50;
  --form-button: #2da5ff;
  --color-grey: #eae6eb;
  --box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html {
  font-size: 10px;
}

body {
  font-family: "Poppins", sans-serif;
}

section {
  width: 100%;
  padding: 8rem 0;
}

/* Text styles */
.u-text-small {
  font-size: 1.2rem;
  line-height: 1.4;
  font-weight: 300;
}

.u-text-dark {
  color: var(--dark-text);
}
.u-text-light {
  color: var(--dark-text);
}

.u-text-center {
  text-align: center;
}

/* Title */
.u-title {
  text-align: center;
  margin-bottom: 4rem;
}

.u-title h2 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.4;
  text-transform: uppercase;
  color: var(--dark-text);
  margin: 1.5rem 0;
}

.u-title p {
  width: 50%;
  min-width: 40rem;
  margin: 0 auto;
  color: var(--dark-text);
}

.header-bg {
  /* position: absolute;
  top: 0;
  left: 0; */
  width: 100%;
  height: 100%;
}

a {
  font-size: 16px;
  font-weight: 300;
  color: #fff;
  text-decoration: none;
  margin-right: 1rem;
  transition: all 0.3s;
}
a:hover,
#nav-links-mobile a:hover {
  color: orangered;
}
a.btn {
  border-radius: 5px;
  padding: 5px 8px;
  font-weight: 500;
  cursor: pointer;
}

a.btn-dark {
  background: transparent;
  border: 2px solid #fff;
}
a.btn-light {
  background: orangered;
  border: 2px solid transparent;
  color: #fff;
}

@media screen and (max-width: 600px) {
  a.btn {
    display: inline;
  }
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 5rem;
  background-color: #4CAF50;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo-container {
  max-width: 100px; /* Adjust as needed */
  max-height: 50px; /* Adjust as needed */
}
.logo-image {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.logo-text {
  font-size: 40px;
  font-weight: 300;
  color: white;
}

.logo-text span {
  font-weight: 600;
  color: orangered;
}

.nav-links {
  display: flex;
  color: #fff;
}

.nav-links li {
  margin-left: 10px;
  list-style: none;
}

.nav-links a {
  font-size: 16px;
  font-weight: 300;
  color: #fff;
  text-decoration: none;
  margin-right: 1rem;
  transition: all 0.3s;
}
.nav-links a:hover,
#nav-links-mobile a:hover {
  color: orangered;
}

/* a.btn {
  border-radius: 5px;
  padding: 5px 8px;
  margin-left: 1rem;
  font-weight: 500;
}

a.btn-dark {
  background: transparent;
  border: 2px solid #fff;
}
a.btn-light {
  background: #fff;
  border: 2px solid transparent;
  color: #0a1930;
} */

.navbar .menu-icons {
  background: transparent;
  border: none;
  display: none;
}

/* Media Queries */
@media screen and (max-width: 769px) {
  /* hide nav button on navbar */
  .nav-btn {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  /* show nav button on navbar menu */
  .nav-btn {
    display: block;
  }

  .nav-links {
    display: none;
    border: 2px solid #fff;
    z-index: 111;
  }

  .navbar .menu-icons {
    display: block;
  }

  .navbar #nav-links-mobile,
  .navbar #nav-links-mobile-hide {
    display: block;
    position: absolute;
    list-style: none;
    background: #4CAF50;
    left: 0;
    top: 80px;
    width: 100%;
    transition: all 0.3s;
    border-top: 1px solid #fff;
    padding-bottom: 5px;
  }

  .navbar #nav-links-mobile {
    animation: slide-in 0.5s ease-in-out;
  }

  .navbar #nav-links-mobile-hide {
    left: -100%;
    animation: slide-out 0.5s ease-in-out;
  }
  @keyframes slide-out {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  @keyframes slide-in {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  .navbar #nav-links-mobile li,
  .navbar #nav-links-mobile-hide li {
    text-align: center;
    padding: 10px 0;
    border-bottom: 1px solid #fff;
    margin: 0 30px;
  }

  .navbar #nav-links-mobile li:last-child {
    border-bottom: none;
  }

  .navbar #nav-links-mobile li a {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
  }

  .navbar #nav-links-mobile a:hover {
    color: orangered;
  }
}

#header {
  position: relative;
  background: linear-gradient(
      rgba(0, 0, 0, 0.7), 
      rgba(0, 0, 0, 0.7)
    ),
    url(/static/media/home-page-background.d8ea8058.jpg) no-repeat center center/cover;
  height: 100vh;
  display: flex;
  flex-direction: column; /* Align logo and text vertically */
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--color-white);
  padding: 20px; /* Added padding for breathing room */
  box-sizing: border-box;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 900px; /* Prevent the content from stretching too much */
  width: 100%;
}

.header-logo {
  max-width: 800px; /* Restrict logo size for better proportionality */
  width: 100%;
  height: auto;
  margin-bottom: 20px; /* Add spacing below the logo */
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.5)); /* Add a subtle shadow for emphasis */
}

.header h1 {
  font-size: 2.5rem; /* Adjust for a balanced look */
  line-height: 1.2;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 1rem;
}

.header h1 span {
  color: var(--accent-color); /* Keep accent color for branding */
}

.header p {
  margin: 1rem 0;
  color: #ddd;
  font-size: 1.2rem;
  line-height: 1.6;
}

.header .cta {
  margin-top: 1.5rem;
  padding: 0.8rem 2rem;
  background-color: var(--accent-color);
  color: #fff;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  text-decoration: none;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.header .cta:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 768px) {
  #header {
    height: auto;
    padding: 40px 20px;
  }

  .header-logo {
    max-width: 200px; /* Adjust logo size for smaller screens */
  }

  .header h1 {
    font-size: 2rem;
  }

  .header p {
    font-size: 1rem;
  }

  .header .cta {
    padding: 0.7rem 1.5rem;
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 480px) {
  .header h1 {
    font-size: 1.8rem;
  }

  .header p {
    font-size: 0.9rem;
  }

  .header .cta {
    padding: 0.6rem 1.2rem;
    font-size: 0.8rem;
  }
}

#features {
  background-color: #fdf6e3; /* Light cream background */
  padding: 4rem 2rem;
}

.feature {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2rem;
}

.feature-icon {
  position: relative;
  margin-right: 2rem;
}

.inner-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.feature-text h3 {
  font-size: 2rem;
  font-weight: 200;
  line-height: 1.4;
  text-transform: uppercase;
  color: var(--light-blue);
}
/* General Container Styling */
#about-us {
  padding: 4rem 2rem;
  background-color: #fdf6e3;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Title Section */
.about-title h2 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #4caf50; /* Matches the green in your logo */
  margin-bottom: 2rem;
  text-align: center;
}

/* About Content */
.about-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  grid-gap: 2rem;
  gap: 2rem;
  margin-top: 2rem;
}

/* Images Section */
.about-images {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.about-img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* Text Section */
.about-text {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}

.about-text h3 {
  font-size: 1.8rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 0.5rem;
}

.about-text p {
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
  text-align: justify;
}

.about-link {
  display: inline-block;
  margin-top: 1rem;
  font-size: 1rem;
  color: #4caf50;
  text-decoration: none;
  font-weight: bold;
}

.about-link:hover {
  color: #388e3c; /* Darker green on hover */
}

/* Responsive Design */
@media (max-width: 768px) {
  .about-content {
    flex-direction: column;
    align-items: center;
  }

  .about-images,
  .about-text {
    width: 100%;
  }
}

/* General Section Styling */
#vision {
    padding: 4rem 2rem;
    background: url(/static/media/our-vision-background.285f9c1a.jpg) no-repeat center center/cover;
    color: #fff; /* Ensure text is readable on the background */
    position: relative;
  }
  
  #vision::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Add a semi-transparent overlay for contrast */
    z-index: 1;
  }
  
  .vision-container {
    position: relative;
    z-index: 2; /* Ensure content appears above the overlay */
  }
  
 
  /* Center the header */
.vision-header {
    text-align: center;
    margin-bottom: 2rem; /* Space between the heading and cards */
    position: relative;
    z-index: 2; /* Ensures it's above any background overlay */
  }
  
  .vision-header h1 {
    font-size: 3rem;
    font-weight: bold;
    color: #ff8c00; /* Vibrant orange for "Our Vision" */
    text-align: center;
    margin: 0; /* Removes any default margins */
    line-height: 1.2;
  }
  
  /* Cards Container */
  .vision-cards {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 2rem;
    gap: 2rem;
    justify-content: center;
  }
  
  /* Individual Cards */
  .vision-card {
    background-color: #487307; /* Green background matching the brand */
    color: #fff;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 2rem;
    flex: 1 1;
    min-width: 300px;
    max-width: 350px;
    text-align: left;
    transition: transform 0.3s ease;
  }
  
  .vision-card:hover {
    transform: translateY(-10px);
  }
  
  /* Card Headings */
  .vision-card h2 {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #fff;
  }
  
  /* Card Text */
  .vision-card p {
    font-size: 1rem;
    line-height: 1.6;
    color: #f1f1f1; /* Slightly lighter text for readability */
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .vision-cards {
      flex-direction: column;
      grid-gap: 1.5rem;
      gap: 1.5rem;
    }
  }
  
/* General Styling */
#technology {
    background-color: #fdf6e3; /* Light cream background */
    padding: 4rem 2rem;
  }
  
  .tech-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
  }
  
  /* Header Styling */
  .tech-header h1 {
    color: #718d4b; /* Olive green color */
    font-size: 2.5rem;
    margin-bottom: 2rem;
    text-align: center;
  }
  
  /* Content Section */
  .tech-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    grid-gap: 2rem;
    gap: 2rem;
  }
  
  /* Text Section */
  .tech-text {
    flex: 1 1;
    min-width: 300px;
  }
  
  .tech-text p {
    color: #6d6d6d; /* Gray text */
    line-height: 1.8;
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
  }
  
  .tech-link {
    display: inline-block;
    margin-top: 1rem;
    color: #ff8c00; /* Orange link */
    font-weight: bold;
    text-decoration: none;
  }
  
  .tech-link:hover {
    text-decoration: underline;
  }
  
  /* Images Section */
  .tech-images {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
  }
  
  .circle-image {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
    border: 4px solid #718d4b; /* Olive green border */
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .circle-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    .tech-content {
      flex-direction: column;
      text-align: center;
    }
  
    .tech-text {
      text-align: center;
    }
  
    .circle-image {
      width: 150px;
      height: 150px;
    }
  }
  
  
