/* General Section Styling */
#vision {
    padding: 4rem 2rem;
    background: url(../../assets/our-vision-background.jpg) no-repeat center center/cover;
    color: #fff; /* Ensure text is readable on the background */
    position: relative;
  }
  
  #vision::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Add a semi-transparent overlay for contrast */
    z-index: 1;
  }
  
  .vision-container {
    position: relative;
    z-index: 2; /* Ensure content appears above the overlay */
  }
  
 
  /* Center the header */
.vision-header {
    text-align: center;
    margin-bottom: 2rem; /* Space between the heading and cards */
    position: relative;
    z-index: 2; /* Ensures it's above any background overlay */
  }
  
  .vision-header h1 {
    font-size: 3rem;
    font-weight: bold;
    color: #ff8c00; /* Vibrant orange for "Our Vision" */
    text-align: center;
    margin: 0; /* Removes any default margins */
    line-height: 1.2;
  }
  
  /* Cards Container */
  .vision-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
  }
  
  /* Individual Cards */
  .vision-card {
    background-color: #487307; /* Green background matching the brand */
    color: #fff;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 2rem;
    flex: 1;
    min-width: 300px;
    max-width: 350px;
    text-align: left;
    transition: transform 0.3s ease;
  }
  
  .vision-card:hover {
    transform: translateY(-10px);
  }
  
  /* Card Headings */
  .vision-card h2 {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #fff;
  }
  
  /* Card Text */
  .vision-card p {
    font-size: 1rem;
    line-height: 1.6;
    color: #f1f1f1; /* Slightly lighter text for readability */
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .vision-cards {
      flex-direction: column;
      gap: 1.5rem;
    }
  }
  