#features {
  background-color: #fdf6e3; /* Light cream background */
  padding: 4rem 2rem;
}

.feature {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2rem;
}

.feature-icon {
  position: relative;
  margin-right: 2rem;
}

.inner-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.feature-text h3 {
  font-size: 2rem;
  font-weight: 200;
  line-height: 1.4;
  text-transform: uppercase;
  color: var(--light-blue);
}
/* General Container Styling */
#about-us {
  padding: 4rem 2rem;
  background-color: #fdf6e3;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Title Section */
.about-title h2 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #4caf50; /* Matches the green in your logo */
  margin-bottom: 2rem;
  text-align: center;
}

/* About Content */
.about-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
  margin-top: 2rem;
}

/* Images Section */
.about-images {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.about-img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* Text Section */
.about-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.about-text h3 {
  font-size: 1.8rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 0.5rem;
}

.about-text p {
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
  text-align: justify;
}

.about-link {
  display: inline-block;
  margin-top: 1rem;
  font-size: 1rem;
  color: #4caf50;
  text-decoration: none;
  font-weight: bold;
}

.about-link:hover {
  color: #388e3c; /* Darker green on hover */
}

/* Responsive Design */
@media (max-width: 768px) {
  .about-content {
    flex-direction: column;
    align-items: center;
  }

  .about-images,
  .about-text {
    width: 100%;
  }
}
